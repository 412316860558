import React, {useState, useEffect} from 'react';
import { Link } from "@StarberryUtils";
import $ from "jquery";
import { useFlexSearch } from 'react-use-flexsearch';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';

const SiteSearch = (props) => {
    const[search_text, setSearchText] = useState('');
    const[searchResult, setSearchResult] = useState('');
    const queryData = useStaticQuery(graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `)
    const index = queryData.localSearchPages.index
    const store = queryData.localSearchPages.store
    const [query, setQuery] = useState('')
    const results = useFlexSearch(query, index, store)

    useEffect(()=>{
        setQuery(search_text);
    },[search_text])

    useEffect(() =>{
        //console.log("results =>", results);
        var search_arr = '';
        if(results && results.length > 0){
            search_arr = {
                "Services": results.filter(item => item.type === "articles").sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)),
                "People": results.filter(item => item.type === "team").sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)),
                "Offices": results.filter(item => item.type === "office").sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)),
                "News": results.filter(item => item.type === "news").sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
            }
        }

        //console.log("search_arr", search_arr)
        setSearchResult(search_arr)
        //props.searchResult(search_arr);

    },[results]);

    return (
        <>
            <div className="ais-SearchBox form-control search-overlay-input">
                <form className="ais-SearchBox-form" action='javascript:;'>
                    <input type="text" placeholder="Search the website" autocomplete="off" maxlength="512" className="ais-SearchBox-input" value={search_text} onChange={(event) => setSearchText(event.target.value)} />
                </form>
            </div>
            {searchResult && 
                <div className="hit-feed">                
                    {searchResult["Services"]?.length > 0 &&
                        <ItemList searchResult={searchResult["Services"]}/>
                    }

                    {Object.keys(searchResult).filter(key => key !== "Services").map((key) =>{
                            //console.log("item", key, search_result[key]);
                            if(searchResult[key].length > 0){
                                return(
                                    <ul onClick={closeSearchOverlay} className="parent_ul">
                                       
                                        <li className="title"><span>{key}</span>
                                            <ul role="listbox">
                                                {
                                                    searchResult[key].map((item) =>{
                                                        return(
                                                            <li role="option">
                                                                <Link to={item.menu.URL}>{item.title}</Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>

                                    </ul>
                                )
                            }
                            
                        })
                    }
                </div>
            }
        </>
    );
}

function closeSearchOverlay(e) {
    $("body").removeClass("modal-open").find(".modal-backdrop").remove()
    $(".search-overlay").removeClass("active-search")
}

function ItemLinks(props) {
    const item = props.item;
    const i = props.iterator;
    if((item?.menu?.Main_Parent !== null) &&(item?.menu?.Sub_Parent !== null)){
        return (
            <li role="option" tabIndex={i}><Link to={"/"+item?.menu?.Main_Parent.URL+"/"+item?.menu?.Sub_Parent?.URL+"/"+item?.menu?.URL}>{item?.menu?.Label}</Link></li>
        )
    } else if ((item?.menu?.Main_Parent !== null) && (item?.menu?.Sub_Parent === null)) {
        return (
            <li role="option" tabIndex={i}><Link to={"/"+item?.menu?.Main_Parent.URL+"/"+item?.menu?.URL}>{item?.menu?.Label}</Link></li>
        );
    } 
    else {
        return (
            <li role="option" tabIndex={i}><Link to={"/"+item?.menu?.URL}>{item?.menu?.Label}</Link></li>
        );
    }
}

const ListItems = ( props ) => {

    //console.log("props_items", props.items);

    const items = props.items;
    let iterator = 0;
    const listItems = items.map((item) => {
        iterator++;
        return (
            <ItemLinks key={item?.menu?.id} iterator={iterator} item={item}/>
        )
    });

    //console.log("listItems", listItems)
    return (
      <ul role="listbox">{listItems}</ul>
    );
}


const ItemList = ({ searchResult }) => {
    let hits = searchResult;
    hits = _.sortBy(hits, ['Sub_Parent_Name']);
    hits = _.groupBy(hits, hit => hit?.Sub_Parent_Name);
    // let itemSize = _.size(hits)

    //console.log("hits =>", hits)
    return (
        <ul onClick={closeSearchOverlay} className="parent_ul">
            {Object.entries(hits).map((hit) => {
                let items = hit[1]
                let title = hit[0]

                //console.log("hit ==>", hit)
                return (
                    <>
                        {title ? 
                        <li className="title"><span>{title}</span>
                            <ListItems items={items}/>
                        </li> : 
                            <ListItems items={items}/>
                        }
                    </>
                )
            })}
        </ul>
    )
};

export default SiteSearch;
